import { Object3D, Quaternion, Vector2, Vector3 } from 'three';
import { degToRad } from 'three/src/math/MathUtils.js';
import { onMounted, ref, watch } from 'vue';

import { lerp, randomInt, toRadians } from '@resn/gozer-math';
import { usePointer, useSpring, useWindowPointer } from '@resn/gozer-vue';
import gsap from '@resn/gsap';
import { CustomWiggle } from '@resn/gsap/CustomWiggle';

const createMotionVectors = () => {
    return { pos: new Vector3(), rot: new Vector3() };
};

const createQuaternions = () => {
    return {
        rot: new Vector3(),
        rotTarget: new Vector3(),
        xAxis: new Vector3(1, 0, 0),
        yAxis: new Vector3(0, 1, 0),
        zAxis: new Vector3(0, 0, 1),
        x: new Quaternion(),
        y: new Quaternion(),
        z: new Quaternion(),
        final: new Quaternion(),
        base: new Quaternion(),
    };
};

export const useCardMotion = ({
    object = null,
    interactive = ref(false),
    motionActive = ref(false),
    onHeartbeatRepeat = () => {},
} = {}) => {
    if (!object) return console.warn('useCardMotion: object is required');

    const tweens = { ambientPr: 0, revealPr: 0, interactivePr: 0 };

    const pointerPrev = new Vector2();

    const rotationV = new Vector3();
    const flipRotationV = new Vector3();

    const innerObject = new Object3D();
    const rotateObject = new Object3D();
    const flipObject = new Object3D();
    const interactionObject = new Object3D();
    const finalObject = interactionObject;
    innerObject.name = 'innerObject';
    rotateObject.name = 'rotateObject';
    flipObject.name = 'flipObject';
    interactionObject.name = 'interactionObject';
    finalObject.name = 'finalObject';

    object.add(innerObject);
    innerObject.add(rotateObject);
    rotateObject.add(flipObject);
    flipObject.add(interactionObject);

    const tempMotion = createMotionVectors();
    const innerMotion = createMotionVectors();
    const ambientMotion = createMotionVectors();
    const lightsDownMotion = createMotionVectors();
    const outMotion = createMotionVectors();
    const interactionMotion = createQuaternions();

    const springOpts = { stiffness: 80, damping: 15 };
    const rotSpring = useSpring(rotationV, springOpts);
    const interactionRotSpring = useSpring(interactionMotion.rot, { stiffness: 200, damping: 30 });

    const pointer = useWindowPointer(({ x, y, fromDown }) => {
        if (!interactive.value) return;

        const dx = x - pointerPrev.x;
        const dy = y - pointerPrev.y;
        const { rotTarget } = interactionMotion;

        if (pointer.isDown.value) {
            rotTarget.x += dy * 0.01;
            rotTarget.y += dx * 0.01;

            interactionRotSpring.set({ x: rotTarget.x, y: rotTarget.y });
        }

        pointerPrev.set(x, y);
    });

    const createWiggleEffect = () => {
        const motion = createMotionVectors();

        const tl = gsap.timeline({ paused: true });
        tl.timeScale(1.5);
        const downDur = 0.2;
        const upDur = 0.8;
        const wiggleDur = 1.5;
        const endStart = downDur + wiggleDur - 0.6;
        const downEase = 'power1.inOut';

        CustomWiggle.create('wiggle', { wiggles: 8, type: 'easeOut' });

        tl.to(motion.pos, { z: -0.3, duration: downDur, ease: downEase }, 0);
        tl.to(motion.pos, { z: 1.2, duration: upDur, ease: 'power3.out' }, downDur);
        tl.to(
            motion.rot,
            {
                z: 0.03,
                duration: wiggleDur,
                ease: 'wiggle',
            },
            downDur
        );
        tl.addLabel('down');
        // tl.to(motion.pos, { z: 0, duration: 1, ease: 'power2.out' }, downDur + upDur + 0.5);
        tl.to(motion.pos, { z: 0, duration: 1, ease: 'power2.inOut' }, endStart);

        return { tl, ...motion, type: 'wiggle' };
    };

    const createSpinEffect = () => {
        const motion = createMotionVectors();

        const tl = gsap.timeline({ paused: true });
        tl.timeScale(1.5);

        const downDur = 0.2;
        const upDur = 0.8;
        const downEase = 'power1.inOut';
        const endStart = downDur + 0.4;

        tl.to(motion.pos, { z: -0.3, duration: downDur, ease: downEase }, 0);
        tl.to(motion.pos, { z: 1.2, duration: upDur, ease: 'power3.out' }, downDur);
        tl.to(motion.pos, { z: 0, duration: 1, ease: 'power2.inOut' }, endStart);

        tl.to(
            motion.rot,
            {
                y: toRadians(360),
                duration: 2.3,
                ease: 'expo.out',
            },
            downDur + 0.05
        );

        return { tl, ...motion, type: 'spin' };
    };

    const createTiltEffect = () => {
        const motion = createMotionVectors();

        const tl = gsap.timeline({
            paused: true,
            onUpdate: () => {
                const angle = toRadians(lerp(tweens.rotatePr, -90, 90));
                const angleStr = lerp(tweens.strPr, 0, 0.3);
                const posStr = lerp(tweens.strPr, 0, 0.075);
                // const posStr = lerp(tweens.strPr, 0, 0.01);

                const angleOffset = toRadians(90);
                motion.rot.x = Math.sin(angle - angleOffset) * angleStr;
                motion.rot.y = Math.cos(angle - angleOffset) * angleStr;
                motion.pos.x = Math.sin(angle) * posStr;
                motion.pos.y = Math.cos(angle) * posStr;
            },
        });
        tl.timeScale(1.5);

        const downDur = 0.2;
        const upDur = 0.8;
        const downEase = 'power1.inOut';
        const pauseDur = 2;
        const endStart = downDur + pauseDur - 0.8;

        const tweens = { rotatePr: 0, strPr: 0 };

        tl.to(motion.pos, { z: -0.3, duration: downDur, ease: downEase }, 0);
        tl.to(motion.pos, { z: 1.2, duration: upDur, ease: 'power3.out' }, downDur);

        tl.to(tweens, { rotatePr: 1, duration: pauseDur, ease: 'sine.inOut' }, downDur);
        tl.to(tweens, { strPr: 1, duration: upDur, ease: 'power3.out' }, downDur);

        tl.to(motion.pos, { z: 0, duration: 1, ease: 'power2.inOut' }, endStart);
        tl.to(tweens, { strPr: 0, duration: 1, ease: 'power2.inOut' }, endStart);

        return { tl, ...motion, type: 'tilt' };
    };

    const createHeartbeatEffect = () => {
        const motion = createMotionVectors();

        const tl = gsap.timeline({
            paused: true,
            repeat: -1,
            repeatDelay: 0.8,
            onStart: onHeartbeatRepeat,
            onRepeat: () => {
                if (heartbeatEffect.active) {
                    onHeartbeatRepeat();
                } else {
                    tl.pause();
                }
            },
        });
        // tl.to(motion.pos, { z: -0.1, duration: 0.1, ease: 'power1.inOut' }, 0);
        tl.to(motion.pos, { z: 0.5, duration: 0.2, ease: 'power1.out' });
        tl.to(motion.pos, { z: 0, duration: 0.8, ease: 'power1.out' });

        return { tl, ...motion };
    };

    // Sweat effects
    const wiggleEffect = createWiggleEffect();
    const spinEffect = createSpinEffect();
    const tiltEffect = createTiltEffect();
    const heartbeatEffect = createHeartbeatEffect();

    const sweatEffects = [wiggleEffect, spinEffect, tiltEffect];

    const updateInteractionMotion = () => {
        const { x, y, z } = interactionMotion.rot;
        interactionMotion.x.setFromAxisAngle(interactionMotion.xAxis, x);
        interactionMotion.y.setFromAxisAngle(interactionMotion.yAxis, y);
        interactionMotion.z.setFromAxisAngle(interactionMotion.zAxis, z);

        interactionMotion.final
            .copy(interactionMotion.x)
            .multiply(interactionMotion.y)
            .multiply(interactionMotion.z);

        interactionMotion.final.slerp(interactionMotion.base, 1 - tweens.interactivePr);
    };

    const update = (timestamp) => {
        const speed = timestamp * 0.0005;
        // ambientMotion.pos.y = Math.sin(speed) * 0.01 * tweens.ambientPr;
        ambientMotion.rot.x = Math.sin(speed) * 0.15 * tweens.ambientPr;
        ambientMotion.rot.y = Math.cos(speed) * 0.15 * tweens.ambientPr;

        updateInteractionMotion();

        // // Inner Object
        tempMotion.pos
            .copy(innerMotion.pos)
            .add(ambientMotion.pos)
            .add(wiggleEffect.pos)
            .add(spinEffect.pos)
            .add(tiltEffect.pos)
            .add(lightsDownMotion.pos)
            .add(outMotion.pos)
            .add(heartbeatEffect.pos);
        tempMotion.rot
            .copy(innerMotion.rot)
            .add(ambientMotion.rot)
            .add(wiggleEffect.rot)
            .add(spinEffect.rot)
            .add(tiltEffect.rot)
            .add(lightsDownMotion.rot)
            .add(outMotion.rot)
            .add(heartbeatEffect.rot);

        innerObject.position.copy(tempMotion.pos);
        innerObject.rotation.setFromVector3(tempMotion.rot);

        interactionObject.quaternion.copy(interactionMotion.final);
        rotateObject.rotation.setFromVector3(rotationV);
        flipObject.rotation.setFromVector3(flipRotationV);
    };

    const reveal = (type = null) => {
        playSweatEffect(type);
    };

    const setFlip = (val = false, force = false) => {
        const _rotation = val ? degToRad(180) : 0;

        const duration = force ? 0 : 0.7;
        gsap.killTweensOf(innerMotion.rot);
        gsap.to(innerMotion.rot, { y: _rotation, duration, ease: 'power2.out' });
    };

    const setRotation = (val = 0, force = false) => {
        rotSpring.set({ z: degToRad(-val) }, force);
    };

    const setZoom = (val = false, force = false) => {
        const zoom = val ? 1.8 : 0;
        const duration = force ? 0 : 0.8;
        gsap.killTweensOf(innerMotion.pos);
        gsap.to(innerMotion.pos, { z: zoom, duration, ease: 'power2.out' });
    };

    const startLightsDownReveal = ({ setDelay = 0, startDelay = 0 } = {}) => {
        const tl = gsap.timeline();

        tl.set(lightsDownMotion.pos, { z: -10 }, setDelay);
        tl.set(lightsDownMotion.rot, { x: toRadians(-90), y: 0 }, setDelay);

        tl.to(lightsDownMotion.pos, { z: 0, duration: 4, ease: 'power4.out' }, startDelay);
        tl.to(lightsDownMotion.rot, { x: 0, duration: 6, ease: 'power4.out' }, startDelay);
        tl.to(
            lightsDownMotion.rot,
            { y: toRadians(-360), duration: 3, ease: 'power2.inOut' },
            startDelay
        );
        return tl;
    };

    let lastEffect;
    // let index = 0;
    const playSweatEffect = (type = null) => {
        let effect;

        if (type) {
            effect = sweatEffects.find((effect) => effect.type === type);
        } else {
            const effects = sweatEffects.slice().filter((effect) => effect !== lastEffect);
            effect = effects[randomInt(0, effects.length - 1)];
        }

        effect.tl.progress(0);
        effect.tl.play();

        lastEffect = effect;
    };

    const playHeartbeatEffect = () => {
        heartbeatEffect.active = true;
        heartbeatEffect.tl.progress(0);
        heartbeatEffect.tl.play();
    };

    const stopHeartbeatEffect = () => {
        heartbeatEffect.active = false;
    };

    const setMotionActive = (val) => {
        const duration = val ? 3 : 0.5;
        const ambientPr = val ? 1 : 0;

        gsap.killTweensOf(tweens);
        gsap.to(tweens, { ambientPr, duration, ease: 'power1.inOut' });
    };

    const internalZoom = ref(false);

    const onDoubleClick = () => {
        internalZoom.value = !internalZoom.value;
        setZoom(internalZoom.value);
    };

    const setInteractive = (val) => {
        const duration = val ? 3 : 3;
        const interactivePr = val ? 1 : 0;

        gsap.killTweensOf(tweens);
        gsap.to(tweens, { interactivePr, duration, ease: 'power1.inOut' });

        if (val) addEventListener('dblclick', onDoubleClick);
        else removeEventListener('dblclick', onDoubleClick);
    };

    const hide = () => {
        gsap.to(outMotion.rot, { x: -0.6, y: 0.6, duration: 1, ease: 'power2.inOut' });
    };

    const reset = () => {
        outMotion.rot.set(0, 0, 0);
    };

    watch(interactive, setInteractive);
    watch(motionActive, setMotionActive);

    return {
        finalObject,
        update,
        hide,
        reset,
        reveal,
        setFlip,
        setRotation,
        setZoom,
        playSweatEffect,
        playHeartbeatEffect,
        stopHeartbeatEffect,
        startLightsDownReveal,
    };
};
